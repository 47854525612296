<template>
  <section class="message task-created">
    <section>
      <hub-icon name="clock-alert" size="xlg" />
      <div>
        <h4>{{ metadata.next?.name }}</h4>
        <div class="assignees">
          <em><label>Responsible Attorney(s)</label>: {{ metadata.next?.responsibleAttorneys?.filter(ra => ra) || [] }}</em>
        </div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(createdAt).toLocaleString() }}
        </div>
        <div v-if="metadata.next?.dueAt">
          Due At <label>{{ new Date(metadata.next?.dueAt).toLocaleDateString() }}</label>
        </div>
        <div v-else>
          <em>No due date</em>
        </div>
        <div>
          <em
            >By <label>{{ createdBy }}</label></em
          >
        </div>
      </div>
      <div>
        <!-- <hub-button variant="icon" @click="download"><hub-icon name="download"></hub-icon></hub-button> -->
      </div>
    </section>
  </section>
</template>

<script>
import Button from './../../common/Button';
import Icon from './../../common/Icon';

export default {
  components: {
    // 'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: Date,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    download() {}
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
